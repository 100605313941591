import BackToTopButtonComponent from '@wix/thunderbolt-elements/src/components/BackToTopButton/viewer/BackToTopButton';
import BackToTopButtonController from '@wix/thunderbolt-elements/src/components/BackToTopButton/viewer/BackToTopButton.controller';
import ExpandableMenuComponent from '@wix/thunderbolt-elements/src/components/ExpandableMenu/viewer/ExpandableMenu';
import MenuContainerComponent from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/MenuContainer';
import MenuToggleComponent from '@wix/thunderbolt-elements/src/components/MenuToggle/viewer/MenuToggle';


const BackToTopButton = {
  component: BackToTopButtonComponent,
  controller: BackToTopButtonController
};

const ExpandableMenu = {
  component: ExpandableMenuComponent
};

const MenuContainer = {
  component: MenuContainerComponent
};

const MenuToggle = {
  component: MenuToggleComponent
};


export const components = {
  ['BackToTopButton']: BackToTopButton,
  ['ExpandableMenu']: ExpandableMenu,
  ['MenuContainer']: MenuContainer,
  ['MenuToggle']: MenuToggle
};

